@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  font-family: "Roboto", sans-serif;
  background: #b41225; /* fallback for old browsers */
}
.header-txt{
  font-family: "MisterEarl";
  color: white;
}

.tab-view{
  font-family: "Freehand";
  font-size: 20px;
  background-color: #b41225;
  border-color: #b41225;
  color: white;
 width:160px;
  padding-right: 10px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}