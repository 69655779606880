body {
    font-family: 'Roboto', sans-serif;
    background: #b41225; /* fallback for old browsers */
  }
  body::-webkit-scrollbar{
	  display: none;
	}
  

 @font-face {
  font-family: "Freehand";
  src: local("FreehandLight"), url("./Fonts/Freehand.ttf") format("truetype");
 }

 @font-face {
  font-family: "BerlinSansMain";
  src: local("BerlinSansMainFB"), url("./Fonts/BRLNSDB.TTF");
 }

 @font-face {
  font-family: "MisterEarl";
  src: local("MisterEarlBTRegular"),url("./Fonts/MisterEarlBTRegular.TTF");
 }

 @font-face {
  font-family: "BerhandTango";
  src: local("BerhandTango"),url("./Fonts/BernhandTango.ttf");
 }

 @font-face {
  font-family: "Calligraph421";
  src: local("Calligraph421BT"),url("./Fonts/Calligraph421BT.TTF");
 }

 @font-face {
  font-family: "dombold";
  src: local("domboldBT"),url("./Fonts/domboldBT.ttf");
 }
 @font-face {
  font-family: "GilSans";
  src: local("GilSansMTCondBold"),url("./Fonts/GilSansMTCondBold.TTF");
 }
 @font-face {
  font-family: "GilSansCond";
  src: local("GilSansMTCondensed"),url("./Fonts/GilSansMTCondensed.TTF");
 }
 @font-face {
  font-family: "Harngton";
  src: local("Harngton"),url("./Fonts/HARNGTON.TTF");
 }

 @font-face {
  font-family: "hobo";
  src: local("HoboStd"),url("./Fonts/HoboStd.otf");
 }
 @font-face {
  font-family: "JohnHandy";
  src: local("JohnHandyLET"),url("./Fonts/JohnHandyLET.TTF");
 }
 @font-face {
  font-family: "ParkAvenue";
  src: local("ParkAvenue"),url("./Fonts/ParkAvenue.TTF");
 }
 @font-face {
  font-family: "TektonPro";
  src: local("TketonPro-Bold"),url("./Fonts/TektonPro-Bold.otf");
 }
 @font-face {
  font-family: "TektonProC";
  src: local("TketonPro-BoldCond"),url("./Fonts/TektonPro-BoldCond.otf");
 }
.list-sty{
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 10px;
  margin-bottom: 5px;
  width: 180px;
}
.img-world{
  width: 300px;
  
}

.courses-banner-img{
  margin-top: -40%;
}
.courses-info h4{
  font-family: "BerlinSansMain";
}
.courses-info-text li{
  font-family: "Freehand";
  font-size: 22px;
  color: rgb(255, 255, 255);
}
/*Courses Accordion Section
--------------------------------*/
.accordion-item .accordion-button{
 background-color: #030000;
 border-radius: 8px;
 color: #fff;
 font-family: "TektonPro";
 font-size: 16px;
  width: 100%;

}

.accordion-body {

 color: #fff;
}

/* Contact Section
--------------------------------*/
#contact {
  background: #030000;
  color:#fff;
}

#contact .info {
  color: #fefefe;
}

#contact .info i {
  font-size: 32px;
  color: #71c55d;
  float: left;
}

#contact .info p {
  padding: 0 0 10px 50px;
  margin-bottom: 20px;
  line-height: 22px;
  font-size: 14px;
}

#contact .info .email p {
  padding-top: 5px;
}

#contact .social-links {
  padding-bottom: 20px;
}

#contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #71c55d;
  color: #fff;
  line-height: 1;
  padding: 6px 0 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#contact .social-links a:hover {
  background: #55b03f;
  color: #fff;
}

#contact .form #sendmessage {
  color: #71c55d;
  border: 1px solid #71c55d;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input, #contact .form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 12px 10px;
  line-height: 1;
}

#contact .form button[type="submit"] {
  background: #71c55d;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

#contact .form button[type="submit"]:hover {
  background: #92d283;
}


@media only screen and (max-width: 580px) {

  .courses-banner-img{
    margin-top: -45%;
  }
  .course-level-section h1{
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {

.courses-banner-img{
  margin-top: -46%;
}


}

@media only screen and (max-width: 390px) {

  .courses-banner-img{
    margin-top: -50%;
  }
}




